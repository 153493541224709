<template>
  <v-container class="py-7">
    <v-form ref="form" lazy-validation>
      <div class="heading mb-7">{{ title }}</div>
      <v-text-field
        class="mb-8"
        label="Название адреса"
        v-model="name"
        outlined
        clearable
        :rules="[(v) => !!v]"
        hide-details
      />

      <div class="suggest-input-wrapper">
        <v-text-field
          id="address-input"
          class="mb-4"
          label="Адрес"
          v-model="addressLine"
          outlined
          clearable
          autocomplete="off"
          :rules="[(v) => !!v]"
          hide-details
          @input="onSearch"
        />
        <div
          v-if="suggests && suggests?.length && addressLine"
          class="suggests-list"
        >
          <div
            v-for="(item, i) in suggests"
            :key="i"
            class="suggest-item"
            @click="onAddressSelect(item)"
          >
            {{ item }}
          </div>
        </div>
      </div>

      <div class="fields">
        <v-text-field
          label="Подъезд"
          type="number"
          v-model="pod"
          outlined
          clearable
          hide-details
          v-if="additionalFields && additionalFields.pod == 2"
        />

        <v-text-field
          label="Домофон"
          type="number"
          v-model="domofon"
          outlined
          clearable
          hide-details
          v-if="additionalFields && additionalFields.pod == 2"
        />

        <v-text-field
          label="Этаж"
          v-model="et"
          outlined
          clearable
          hide-details
          type="number"
          v-if="additionalFields && additionalFields.et == 2"
        />

        <v-text-field
          v-if="additionalFields && additionalFields.flat == 2"
          label="Квартира"
          v-model="zipcode"
          outlined
          clearable
          :rules="[(v) => !!v]"
          hide-details
          type="number"
        />
      </div>

      <!-- <v-switch v-model="defaultAddress" label="Использовать по умолчанию" class="mb-4" inset /> -->
    </v-form>
  </v-container>
</template>

<script>
import { loadYmap } from 'vue-yandex-maps'
import fetchJsonp from 'fetch-jsonp'
import { mapState, mapGetters } from 'vuex'
import actionButtonMixin from '@/mixins/actionButton'

export default {
  name: 'AddressDetail',

  mixins: [actionButtonMixin],

  data: () => ({
    loading: false,
    cityCoordinates: null,
    defaultAddress: false,
    name: '',
    addressLine: '',
    suggests: [],

    street: '',
    state: '',
    domofon: '',
    zipcode: '',
    pod: '',
    et: '',
  }),

  computed: {
    actionButton() {
      return {
        active: true,
        action: this.save,
        text: 'Сохранить',
        loading: this.loading,
      }
    },

    mapSettings() {
      return {
        apiKey: this.$store.state.app.settings.merchant_yandex_api_key,
      }
    },

    city() {
      return this.$store.getters['app/city']
    },

    id() {
      return this.$route.params.id
    },

    title() {
      return this.id && this.id != 0 ? 'Обновить адрес' : 'Новый адрес'
    },

    ...mapState('user', { address: 'addressDetail' }),
    ...mapGetters('app', ['additionalFields']),
  },

  methods: {
    validate() {
      return this.$refs.form.validate()
    },

    async loadAddress() {
      const { commit, dispatch } = this.$store
      commit('overlay/update', { active: true })
      await dispatch('user/loadAddress', this.id)

      this.street = this.address?.street?.trim()
      this.state = this.address?.state
      this.zipcode = this.address?.zipcode
      this.et = this.address?.et
      this.pod = this.address?.pod
      this.domofon = this.address?.domofon

      if (this.street) {
        const arr = this.street.split(', ')
        this.addressLine = [arr[1], this.state].filter(Boolean).join(', ')
      }

      this.name = this.address?.location_name

      commit('overlay/update', { active: false })
    },

    async querySuggests() {
      let url =
        'https://suggest-maps.yandex.ru/suggest-geo?' +
        'bases=geo,biz&origin=jsapi2Geocoder&fullpath=1&lang=ru_RU&pos=10&v=9' +
        '&part=' +
        encodeURIComponent(this.addressLine)

      if (this.cityCoordinates) {
        url += '&ll=' + this.cityCoordinates.join(',')
        url += '&spn=2,2'
      }

      const response = await fetchJsonp(url).then((r) => r.json())

      this.suggests = response.results
        ?.filter((item) => {
          return (
            item.tags &&
            (item.tags.includes('street') || item.tags.includes('house')) &&
            !item.text.includes(', подъезд')
          )
        })
        .map((item) => item.text.trim())
    },

    onSearch() {
      if (!this.addressLine) return

      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.querySuggests()
      }, 750)
    },

    onAddressSelect(value) {
      this.suggests = []
      this.geoCode(value)
    },

    shortAddress() {
      if (this.state) {
        const arr = this.street.split(', ')
        return [arr[1], this.state].join(', ')
      } else {
        return this.street
      }
    },

    async geoCode(query) {
      const res = await window.ymaps.geocode(query, { results: 1 })

      const geoObject = res.geoObjects.get(0)
      // const addressName = geoObject.getAddressLine()

      const localities = geoObject.getLocalities()
      const state = geoObject.getPremiseNumber()
      const street =
        geoObject.getThoroughfare() ||
        (state && localities?.[localities.length - 1])

      // формат улицы для бэкенда - "Населённый пункт, Улица"
      this.street = [localities[0], street].filter(Boolean).join(', ')
      this.state = state

      this.addressLine = this.shortAddress()
    },

    async save() {
      const { commit, dispatch } = this.$store
      if (!this.validate()) return
      this.loading = true
      const params = {
        location_name: this.name,
        street: this.street,
        state: this.state,
        zipcode: this.zipcode,
        pod: this.pod,
        et: this.et,
        domofon: this.domofon,
        // as_default: this.defaultAddress
      }
      if (this.id) params.book_id = this.id
      const response = await dispatch('user/saveAddress', params)
      this.loading = false
      const text =
        response.code == 1
          ? 'Адрес успешно сохранен'
          : 'Возникла ошибка при сохранении адреса'
      commit('snackbar/update', { active: true, text })
      this.$router.back()
    },
  },

  async created() {
    if (this.id) {
      this.loadAddress()
    } else {
      this.addressLine = this.city + ','
    }
  },

  async mounted() {
    await loadYmap({ ...this.mapSettings })
    await window.ymaps.geocode(this.city).then((res) => {
      const firstGeoObject = res.geoObjects.get(0)
      this.cityCoordinates = firstGeoObject?.geometry
        ?.getCoordinates()
        ?.reverse()
    })
  },
}
</script>

<style lang="scss" scoped>
.fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
</style>
